
import { saveAs } from "file-saver";
import { defineComponent, reactive, toRefs, ref } from "vue";
import loading from "vue";
import ApiService from "@/core/services/ApiService";
import { hideModal } from "@/core/helpers/dom";
import { ElNotification } from "element-plus";
import Swal from "sweetalert2/dist/sweetalert2.js";

export default defineComponent({
  name: "export-audit-modal",
  components: {},
  data() {
    return {
      initialPageSize: "100",
      pageNumber: "0",
      loading: false,
      value2: "",
      value1: new Date(),
      today: new Date(),
      startDate: new Date(),
      endDate: new Date(),
      apiData: [],
      exportFlag: "False",
      // render: false,
      organizationId: "",
      organizationRole: "",
      shortcuts: [
        {
          text: "Last week",
          value: () => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            return [start, end];
          },
        },
        {
          text: "Last month",
          value: () => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            return [start, end];
          },
        },
        {
          text: "Last 3 months",
          value: () => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            return [start, end];
          },
        },
      ],
    };
  },
  methods: {
    resetform() {
      (this.$refs["resetForm"] as any).click();
    },
    callUpdate(orgId, orgRol) {
      this.organizationId = orgId;
      this.organizationRole = orgRol;
    },
    download() {
      this.loading = true;
      var keys = Object.keys(this.apiData[0]);
      let csv = "";
      csv += keys.join(",") + "\n";
      for (var line of this.apiData) {
        csv += keys.map((key) => JSON.stringify(line[key])) + "\n";
      }
      var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      this.loading = false;
      saveAs(blob, "API Logs.csv");
    },
    formReset(date) {
      (this.value1 = new Date()),
        (this.today = new Date()),
        (this.startDate = new Date()),
        (this.endDate = new Date()),
        (this.apiData = []),
        (this.exportFlag = "False"),
        (this.value2 = "");
    },
    getaudit(date) {
      //   var todaydate =
      //     this.today.getFullYear() +
      //     "-" +
      //     (this.today.getMonth() + 1) +
      //     "-" +
      //     this.today.getDate();
      if (this.value2 == null) {
        // this.getaudit()
        ElNotification({
          title: "Error",
          message: "Date cannot be null",
          type: "error",
          duration: 2000,
          position: "top-right",
        });

        //   } else if (this.value1 != null && Object.keys(this.value1).length != 2) {
        // var valuedate =
        //   this.value1.getFullYear() +
        //   "-" +
        //   (this.value1.getMonth() + 1) +
        //   "-" +
        //   this.value1.getDate();
        // if (valuedate == todaydate) {
        //     this.loading=false;
        //   ElNotification({
        //     title: "Error",
        //     message: "Date cannot be null",
        //     type: "error",
        //     duration: 2000,
        //     position: "top-right",
        //   });
        // }
      } else {
        this.startDate = date[0].toISOString().replace("T", " ").split(".")[0];
        this.endDate = date[1].toISOString().replace("T", " ").split(".")[0];
        this.exportFlag = "True";
        ApiService.get(
          "/apiLog?organizationId=" +
            this.organizationId +
            "&organizationType=" +
            this.organizationRole +
            "&startDate=" +
            this.startDate +
            "&endDate=" +
            this.endDate +
            "&pageSize=" +
            this.initialPageSize +
            "&pageNumber=" +
            this.pageNumber
        )
          .then(({ data }) => {
            this.loading = true;
            this.apiData = data.data;
            this.download();
            ElNotification({
              title: "Success",
              message: "Logs exported successfully!",
              type: "success",
              duration: 2000,
              position: "top-right",
            });
            (this.$refs["resetForm"] as any).click();
            hideModal(this.exportAuditModalRef);
            this.$emit("changeTitle", "false");
          })
          .catch((error) => {
            ElNotification({
              title: "Warning",
              message: JSON.stringify(
                error.response.data["errors"][0]["errorMessage"]
              ),
            //   type: "Warning",
              duration: 2000,
              position: "top-right",
            });
          });
      }
    },
  },
  setup() {
    const submitButtonRef = ref<null | HTMLButtonElement>(null);
    const exportAuditModalRef = ref<null | HTMLElement>(null);

    return {
      submitButtonRef,
      exportAuditModalRef,
    };
  },
});
